import React from 'react'
import ContentSlice from '../components/common/ContentSlice'
import Layout from '../components/layout'
class RootIndex extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <ContentSlice filter="terms" />
      </Layout>
    )
  }
}

export default RootIndex
